'use client';

import Button from '@trustblock/components/standalone/Button/Button';
import { truncateString } from '@trustblock/helpers/formatters';
import { UserAuthenticationState, UserType } from '@trustblock/types/user.types';
import useAuth from '@trustblock/web/hooks/useAuth';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect } from 'react';

export default function AuthHandler() {
  const { isAuthenticating, isLogoutLoading, login, logout, user, userAuthenticationState, hasAuthenticationRun } =
    useAuth();
  const router = useRouter();
  const pathname = usePathname();
  const shouldLoginBeDisabled = isAuthenticating;

  useEffect(() => {
    if (
      hasAuthenticationRun &&
      userAuthenticationState === UserAuthenticationState.ConnectedWithoutUser &&
      !pathname.includes('/sign-up')
    ) {
      void router.push('/sign-up');
    }
  }, [userAuthenticationState, hasAuthenticationRun, router, pathname]);

  const isFullySignedIn = userAuthenticationState === UserAuthenticationState.SignedIn && !!user;

  return (
    <div data-testid="auth-handler-container" className="flex flex-col items-center gap-2">
      {userAuthenticationState !== UserAuthenticationState.Visitor && (
        <div className="flex items-center justify-center">
          <Button
            mode="primary"
            icon="User"
            type={isFullySignedIn ? 'link' : 'button'}
            loading={isAuthenticating || !isFullySignedIn}
            href={`/${user?.userType === UserType.Auditor ? 'auditor' : 'integrator'}/${user?.slug}`}
            data-testid="auth-handler-profile-button"
            className="rounded-r-none"
          >
            {isFullySignedIn ? truncateString(user.name, 15) : 'Onboarding'}
          </Button>

          <Button
            data-testid="auth-handler-logout-button"
            onClick={() => {
              void logout();
            }}
            loading={isLogoutLoading}
            mode="secondary"
            icon="Logout"
            onlyIcon
            className="rounded-l-none"
          />
        </div>
      )}

      {userAuthenticationState === UserAuthenticationState.Visitor && (
        <Button
          className="border border-white107"
          data-testid="auth-handler-login-button"
          onClick={login}
          loading={isAuthenticating}
          disabled={shouldLoginBeDisabled}
          fill
          mode="ternary"
          icon="Login"
        >
          Connect
        </Button>
      )}
    </div>
  );
}
